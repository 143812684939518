/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ProjectCard from "../components/ProjectCard.js";
//import SEO from "../components/seo";

const projects = ({ data }) => {
  //const posts = data.allMdx.nodes;
  const pers = data.allMdx.nodes.filter((post) =>
    post.slug.includes("/personal/")
  );
  const work = data.allMdx.nodes.filter((post) =>
    post.slug.includes("/work/")
  );

  /* <SEO title={siteTitle} description={siteDescription} /> */
  return (
    <Layout styling={"layout.primary"} additionalStyles={{maxWidth: "90rem"}}>
      <div
        sx={{
          m: [".5rem", "1rem"],
        }}
      >
        <h2>Personal Projects</h2>
        <p>Here are some things I've done for fun...</p>
      </div>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["100%", "50% 50%"],
        }}
      >
        {pers.map((post) => {
          return <ProjectCard post={post} key={post.slug} />;
        })}
      </div>
      <div
        sx={{
          m: [".5rem", "1rem"],
        }}
      >
        <h2>Work Experience</h2>
        <p>Here are some things I've done for work...</p>
      </div>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["100%", "50% 50%"],
          paddingBottom: "2rem",
        }}
      >
        {work.map((post) => {
          return <ProjectCard post={post} key={post.slug} />;
        })}
      </div>
    </Layout>
  );
};

export default projects;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { nin: "blog" } } }
    ) {
      nodes {
        slug
        frontmatter {
          description
          title
          embeddedImagesLocal {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
