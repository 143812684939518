/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import Icon from "../images/svg/logo.svg";

const ProjectCard = (props) => {
  const post = props.post;
  const title = post.frontmatter.title || post.fields.slug;

  return (
    <Link
      sx={{
        color: "inherit",
        textDecoration: "none",
        m: [".5rem", ".5rem", "1rem"],
        textAlign: "left",
      }}
      to={post.slug.slice(9)}
    >
      <div
        sx={{
          backgroundColor: "secondary",
          borderRadius: "1rem",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          "&:hover": {
            boxShadow: (theme) => `0 0 1em ${theme.colors.highlighted}`,
          },
        }}
      >
        {post.frontmatter.embeddedImagesLocal ? (
          <GatsbyImage
            sx={{
              zindex: "0",
              borderRadius: "1rem 1rem 4px 4px",
              flex: "1",
            }}
            alt="green doggo"
            image={getImage(post.frontmatter.embeddedImagesLocal[0])}
          />
        ) : (
          <div
            sx={{
              flex: "1",
              px: ["1rem", "1rem", "2rem"],
              paddingTop: "1rem",
              paddingBottom: "0rem",
              margin: "auto",
            }}
          >
            <Icon
            sx={{
              height: "100%",
              width: "100%",
              maxWidth: "30rem",
            }}
            />
          </div>
        )}
        <div
          sx={{
            p: [".5rem", ".5rem", "1rem"],
            flex: "1",
          }}
        >
          <h2>{title}</h2>
          <p
            sx={{
              marginTop: "-1.0em",
              p: ".5em",
              fontWeight: "medium",
            }}
          >
            {post.frontmatter.description}
          </p>
        </div>
        <div sx={{ m: "auto" }} />
      </div>
    </Link>
  );
};

export default ProjectCard;
